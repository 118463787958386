<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12" class="refundForms">
                                <b-overlay :show="load">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Application ID" vid="application_id" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="application_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.application_id')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="application_id"
                                                            v-model="deduct.application_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="IrriPayment ID" vid="irri_payment_id" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="irri_payment_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.irriPayment_id')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="irri_payment_id"
                                                            v-model="deduct.payment_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Application Type ID" vid="application_type_id" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="application_type_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.application_type_id')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="application_type_id"
                                                            v-model="application_type_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="User Name" vid="user_name" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="user_name"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="user_name"
                                                            v-model="deduct.user_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Mobile Number" vid="mobile_no">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="mobile_no"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.mobile_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="mobile_no"
                                                            v-model="deduct.mobile_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Payment Type ID" vid="payment_type_id" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="payment_type_id"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.payment_type_id')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="payment_type_id"
                                                            v-model="payment_type_name"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Account No" vid="account_no" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="account_no"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.account_no')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            id="account_no"
                                                            v-model="deduct.account_no"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Present Balance" vid="present_balance">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="present_balance"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.present_balance')}}
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="present_balance"
                                                            v-model="present_balance"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Deduction Amount" vid="deduction_amount" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="deduction_amount"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.deduction_amount')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            @keypress="checkCurrency($event)"
                                                            :max="present_balance"
                                                            id="deduction_amount"
                                                            v-model="deduct.deduction_amount"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Deduction Amount" vid="balance_after_deduction">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="balance_after_deduction"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.balance_after_deduction')}}
                                                        </template>
                                                        <b-form-input
                                                            readonly
                                                            id="balance_after_deduction"
                                                            v-model="balance_after_deduction"
                                                            :state="balance_after_deduction < 0 ? false : (errors[0] ? false : (valid ? true : null))"
                                                        >
                                                        </b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="6" sm="12">
                                                <ValidationProvider name="Date" vid="operation_date" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-for="operation_date"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label>
                                                            {{$t('irrigation_config.date')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-input
                                                            class="date-picker"
                                                            v-model="deduct.operation_date"
                                                            placeholder="Select Date"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        ></b-form-input>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <b-row>
                                            <b-col lg="12" sm="12">
                                                <ValidationProvider name="Deduction Reason" vid="reason" rules="required">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="4"
                                                        label-cols-lg="2"
                                                        label-for="reason"
                                                        slot-scope="{ valid, errors }"
                                                    >
                                                        <template v-slot:label style="font-size:10px;" class="reason">
                                                            {{$t('irrigation_config.deduction_reason')}} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-textarea
                                                            id="reason"
                                                            v-model="deduct.reason"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        </b-form-textarea>
                                                        <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                </ValidationProvider>
                                            </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                &nbsp;
                                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('deductModal')">{{ $t('globalTrans.cancel') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<style>
.refundForms label {
    font-size: 11px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { refundDeductStore } from '../../api/routes'
import flatpickr from 'flatpickr'
import { currency } from '@/Utils/fliter'

export default {
    props: ['id', 'item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        // const applicationId = this.item.far_application_id
        // this.deduct = Object.assign({}, this.item, this.item.far_application_id)
    },
    mounted () {
        core.index()
        flatpickr('.date-picker', {})
    },
    data () {
        return {
            load: false,
            totalPost: 0,
            saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
            deduct: {
                application_id: this.item.far_application_id,
                payment_id: this.item.id,
                application_type_id: this.item.application_type,
                payment_type_id: this.item.payment_type_id,
                operation_date: '',
                account_no: '',
                mobile_no: this.item.farmer_basic_infos.mobile_no,
                reason: '',
                deduction_amount: '',
                operation_type: 2,
                user_name: this.$i18n.locale === 'bn' ? this.item.farmer_basic_infos.name_bn : this.item.farmer_basic_infos.name
            },
            balance_after_deduction: '',
            payment_type_name: this.$i18n.locale === 'bn' ? this.item.payment_type_bn : this.item.payment_type,
            application_type_name: this.$i18n.locale === 'bn' ? this.item.application_type_bn : this.item.application_type_en
        }
    },
    watch: {
      'deduct.deduction_amount': function (newVal, oldVal) {
          if (newVal !== oldVal) {
              this.balance_after_deduction = this.present_balance - this.deduct.deduction_amount
          }
      }
    },
    computed: {
        present_balance () {
            let presentBalance = this.item.amount
            if (typeof this.item.refund_deducts[0] !== 'undefined') {
                presentBalance = this.item.refund_deducts[0].present_balance
            }
            return presentBalance
        }
    },
    methods: {
        checkCurrency ($event) {
            return currency($event, this.deduct.deduction_amount)
        },
        async register () {
            this.load = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            result = await RestApi.postData(irriSchemeServiceBaseUrl, refundDeductStore, this.deduct)

            if (result.success) {
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })

                this.$toast.success({
                    title: 'Success',
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('deductModal')
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: result.errors
                })
                this.$refs.form.setErrors(result.errors)
            }
            this.load = false
        }
    }
}
</script>
