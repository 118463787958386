<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irrigation_config.refund_deduction') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                v-model="search.org_id"
                :options="organizationList"
                id="org_id"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.application_type')"
              label-for="application_type"
            >
              <b-form-select
                plain
                v-model="search.application_type"
                :options="applicationTypeList"
                id="application_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('irrigation_config.payment_type')"
              label-for="payment_type"
            >
              <b-form-select
                plain
                v-model="search.payment_type_id"
                :options="paymentTypeList"
                id="payment_type"
                >
                <template v-slot:first>
                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="4"
              :label="$t('org_pro_division.division')"
              label-for="division_id"
              >
              <b-form-select
              plain
              v-model="search.division_id"
              :options="divisionList"
              id="division_id"
              >
                <template v-slot:first>
                  <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_district.district')"
                        label-for="district_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.district_id"
                        :options="districtList"
                        id="district_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('org_pro_upazilla.upazilla')"
                        label-for="upazilla_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.upazilla_id"
                        :options="upazilaList"
                        id="upazilla_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col lg="6" md="6" sm="12" xs="12">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t($t('org_pro_union.union'))"
                        label-for="union_id"
                        >
                        <b-form-select
                        plain
                        v-model="search.union_id"
                        :options="unionList"
                        id="union_id"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                        </template>
                        </b-form-select>
                    </b-form-group>
                </b-col>
                <b-col lg="6" md="6" sm="12" xs="12">
                  <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('irrigation_config.id')"
                    label-for="far_application_id"
                    >
                    <b-form-input
                      id="far_application_id"
                      v-model="search.far_application_id"
                      placeholder=""
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col cols="12" class="text-right">
        <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
            </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irrigation_config.refund_deduction_list') }}</h4>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(organization)="data">
                      {{ data.item.organization }}
                    </template>
                    <template v-slot:cell(application_type)="data">
                      {{ data.item.application_type }}
                    </template>
                    <template v-slot:cell(payment_type)="data">
                      {{ data.item.payment_type }}
                    </template>
                    <template v-slot:cell(participation_category)="data">
                      {{ data.item.participation_category }}
                    </template>
                    <template v-slot:cell(name)="data">
                      {{ data.item.farmer_basic_infos.name }}
                    </template>
                    <template v-slot:cell(name_bn)="data">
                      {{ data.item.farmer_basic_infos.name_bn }}
                    </template>
                    <template v-slot:cell(mobile_no)="data">
                      {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.farmer_basic_infos.mobile_no, { useGrouping: false }) }}
                    </template>
                    <template v-slot:cell(amount)="data">
                      {{ $n(data.item.amount) }}
                    </template>
                    <template v-slot:cell(present_balance)="data">
                      {{ $n(getPresentBalance(data)) }}
                    </template>
                    <template v-slot:cell(effective_from)="data">
                      {{ data.item.effective_from }}
                    </template>
                    <template v-slot:cell(operation_type)="data">
                      <div v-html="getPreviousAction(data)" />
                      <span>
                        <slot v-if="data.item.refund_deducts.length>0">
                          {{$t('irrigation_config.refund_reason')}}:
                          {{data.item.refund_deducts[0].reason}}
                        </slot>
                      </span>
                      <br/>
                      <span>
                        <slot v-if="data.item.refund_deducts.length>0">
                          {{$t('irrigation_config.date')}}:
                          {{data.item.refund_deducts[0].operation_date  | formatDateDMY}}
                        </slot>
                      </span>
                    </template>
                    <template v-slot:cell(action)="data">
                        <b-button-group>
                            <b-button title="Refund Payment" v-b-modal.refundModal variant="success iq-bg-success mr-1" size="sm" @click="refund(data.item)">{{$t('irrigation_config.refund')}}</b-button>
                            <b-button title="Deduct Payment" v-b-modal.deductModal variant="danger iq-bg-danger" size="sm" @click="deduct(data.item)">{{$t('irrigation_config.deduct')}}</b-button>
                        </b-button-group>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
   <b-modal :id="currentModalID" size="lg" :title="$t('irrigation_config.' + headerTitle)" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
        <component :is="currentModal" :item="item" />
   </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '@/config/api_config'
import { refundDeductList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import deductForm from './deductForm'
import refundForm from './refundForm'
import { mapGetters } from 'vuex'

export default {
    name: 'RefundDeductList',
    mixins: [ModalBaseMasterList],
    components: {
        deductForm, refundForm
    },
    data () {
        return {
            currentModal: 'deductForm',
            currentModalID: 'refundModal',
            search: {
                org_id: 0,
                application_type: 0,
                payment_type_id: 0,
                division_id: 0,
                district_id: 0,
                upazilla_id: 0,
                union_id: 0,
                far_application_id: ''
            },
            id: 0,
            item: '',
            headerTitle: '',
            organizationList: [],
            districtList: [],
            upazilaList: [],
            unionList: [],
            applicationTypeList: [],
            paymentTypeList: []
            }
    },
    computed: {
          ...mapGetters({
          authUser: 'Auth/authUser'
        }),
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('irrigation_config.application_type'), class: 'text-center' },
                { label: this.$t('irrigation_config.payment_type'), class: 'text-center' },
                { label: this.$t('irrigation_config.id'), class: 'text-center' },
                { label: this.$t('irrigation_config.applicant_name'), class: 'text-center' },
                { label: this.$t('irrigation_config.mobile_no'), class: 'text-center' },
                { label: this.$t('irrigation_config.amount_paid'), class: 'text-center' },
                { label: this.$t('irrigation_config.present_balance'), class: 'text-center' },
                { label: this.$t('irrigation_config.previous_action'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                    { key: 'index' },
                    { key: 'application_type_bn' },
                    { key: 'payment_type_bn' },
                    { key: 'far_application_id' },
                    { key: 'name_bn' },
                    { key: 'mobile_no' },
                    { key: 'amount' },
                    { key: 'present_balance' },
                    { key: 'operation_type' },
                    { key: 'action' }
                ]
            } else {
                keys = [
                    { key: 'index' },
                    { key: 'application_type_en' },
                    { key: 'payment_type' },
                    { key: 'far_application_id' },
                    { key: 'name' },
                    { key: 'mobile_no' },
                    { key: 'amount' },
                    { key: 'present_balance' },
                    { key: 'operation_type' },
                    { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        orgList: function () {
            return this.$store.state.commonObj.organizationProfileList
        },
        divisionList: function () {
            return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
        }
    },
    watch: {
      'search.division_id': function (newVal, oldVal) {
        this.districtList = this.getDistrictList(newVal)
      },
      'search.district_id': function (newVal, oldVal) {
        this.upazilaList = this.getUpazilaList(newVal)
      },
      'search.upazilla_id': function (newVal, oldVal) {
        this.unionList = this.getUnionList(newVal)
      },
      'search.application_type': function (newVal, oldVal) {
        this.getPaymentType(newVal)
      }
    },
    created () {
      this.getOrganizationlist()
      this.getApplicationTypeList()
    },
    mounted () {
      core.index()
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          division_id: this.authUser.office_detail.division_id,
          district_id: this.authUser.office_detail.district_id,
          upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    methods: {
        getPreviousAction (data) {
            if (typeof data.item.refund_deducts[0] !== 'undefined') {
                const parent = data.item.refund_deducts[0]
                if ([1, 2].includes(parent.operation_type)) {
                    const isRefund = parent.operation_type === 1
                    const refundDeduct = isRefund ? this.$t('irrigation_config.refund') : this.$t('irrigation_config.deduct')
                    const amount = isRefund ? this.$t('irrigation_config.refund_amount') + ': ' + this.$n(parent.refund_amount) : this.$t('irrigation_config.deduction_amount') + ': ' + this.$n(parent.deduction_amount)
                    const after = isRefund ? this.$t('irrigation_config.refund_balance') + ': ' + this.$n(parent.refund_balance) : this.$t('irrigation_config.deduct_balance') + ': ' + this.$n(parent.balance_after_deduction)
                    return '<span class="badge badge-success">' + refundDeduct + '</span>' + '<br>' + amount + '<br>' + after
                }
            } else {
                return 'N/A'
            }
        },
        getPresentBalance (data) {
            let presentBalance = data.item.amount
            if (typeof data.item.refund_deducts[0] !== 'undefined') {
                presentBalance = data.item.refund_deducts[0].present_balance
            }
            return presentBalance
        },
        refund (item) {
            this.headerTitle = 'refund'
            this.currentModal = 'refundForm'
            this.currentModalID = 'refundModal'
            this.item = item
        },
        deduct (item) {
            this.headerTitle = 'deduct'
            this.currentModal = 'deductForm'
            this.currentModalID = 'deductModal'
            this.item = item
        },
        searchData () {
          if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
            this.search = Object.assign({}, this.search, {
              org_id: this.authUser.org_id
            })
          } else if (this.authUser.role_id === 0) {
            this.search = Object.assign({}, this.search, {
              org_id: this.authUser.office_detail.org_id,
              division_id: this.authUser.office_detail.division_id,
              district_id: this.authUser.office_detail.district_id,
              upazilla_id: this.authUser.office_detail.upazilla_id
            })
          }
          this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(irriSchemeServiceBaseUrl, refundDeductList, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.dataList(response.data.data))
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            }).catch(() => {
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        dataList (data) {
            return data.map((item) => {
                var storeItemList = false
                // Scheme
                if (item.application_type === 1) {
                    storeItemList = this.$store.state.commonObj.schemePaymentList
                }
                // Pump Operation
                if (item.application_type === 2) {
                    storeItemList = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
                }
                // Smart Card
                if (item.application_type === 3) {
                    storeItemList = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
                }
                // Water Testing
                if (item.application_type === 4) {
                    storeItemList = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
                }
                let appData = {}
                if (storeItemList && item.payment_type_id) {
                    const tmpData = storeItemList.find(el => el.id === item.payment_type_id)
                    appData = {
                        payment_type: typeof tmpData !== 'undefined' ? tmpData.name : 'N/A',
                        payment_type_bn: typeof tmpData !== 'undefined' ? tmpData.name_bn : 'N/A'
                    }
                } else {
                    appData = {
                        payment_type: 'N/A',
                        payment_type_bn: 'N/A'
                    }
                }
                const appType = this.$store.state.commonObj.paymentApplicationTypeList.find(el => el.id === item.application_type)
                return Object.assign({}, item, appData, { application_type_en: appType.name, application_type_bn: appType.name_bn })
            })
        },
        getOrganizationlist () {
            this.organizationList = this.$store.state.commonObj.organizationProfileList
        },
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
            if (divisionId) {
                return districtList.filter(district => district.division_id === divisionId)
            }
            return districtList
        },
        getUpazilaList (districtId = null) {
            const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
            if (districtId) {
                return upazilaList.filter(upazila => upazila.district_id === districtId)
            }
            return upazilaList
        },
        getUnionList (upazillaId = null) {
            const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
            if (upazillaId) {
                return unionList.filter(union => union.upazilla_id === upazillaId)
            }
            return unionList
        },
        getPaymentType (applicationTypeId) {
            if (applicationTypeId === 1) {
                this.schemePaymentList()
            } else if (applicationTypeId === 2) {
                this.pumpOperatorPaymentList()
            } else if (applicationTypeId === 3) {
                this.smartCardList()
            } else if (applicationTypeId === 4) {
                this.waterTestingList()
            }
        },
        waterTestingList () {
            const ObjectData = this.$store.state.commonObj.paymetWaterTestingPaymentTypeList
            this.paymentTypeList = ObjectData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
                } else {
                return { value: obj.id, text: obj.name }
                }
            })
        },
        smartCardList () {
            const ObjectData = this.$store.state.commonObj.paymetSmartCardPaymentTypeList
            this.paymentTypeList = ObjectData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
                } else {
                return { value: obj.id, text: obj.name }
                }
            })
        },
        schemePaymentList () {
            const ObjectData = this.$store.state.commonObj.schemePaymentList
            this.paymentTypeList = ObjectData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
                } else {
                return { value: obj.id, text: obj.name }
                }
            })
        },
        pumpOperatorPaymentList () {
            const ObjectData = this.$store.state.commonObj.paymetPumpOperationPaymentTypeList
            this.paymentTypeList = ObjectData.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
                } else {
                return { value: obj.id, text: obj.name }
                }
            })
        },
        getApplicationTypeList () {
            const applicationData = this.$store.state.commonObj.paymentApplicationTypeList
            this.applicationTypeList = applicationData.map((obj) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
                } else {
                return { value: obj.id, text: obj.name }
                }
            })
        }
    }
}
</script>
