<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12" class="refundForm">
                                <b-overlay :show="load">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                                            <b-row>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Application ID" vid="application_id" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="application_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.application_id')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                readonly
                                                                id="application_id"
                                                                v-model="refund.application_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="IrriPayment ID" vid="irri_payment_id" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="irri_payment_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.irriPayment_id')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                readonly
                                                                id="irri_payment_id"
                                                                v-model="refund.payment_id"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Application Type ID" vid="application_type_id" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="application_type_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.application_type_id')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                readonly
                                                                id="application_type_id"
                                                                v-model="application_type_name"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Payment Type ID" vid="payment_type_id" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="payment_type_id"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.payment_type_id')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                readonly
                                                                id="payment_type_id"
                                                                v-model="payment_type_name"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Mobile Number" vid="mobile_no">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="mobile_no"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.mobile_no')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                readonly
                                                                id="mobile_no"
                                                                v-model="refund.mobile_no"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="User Name" vid="user_name" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="user_name"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('globalTrans.name')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                readonly
                                                                id="user_name"
                                                                v-model="refund.user_name"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Refund Amount" vid="refund_amount" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="refund_amount"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.refund_amount')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                id="refund_amount"
                                                                v-model="refund.refund_amount"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Account No" vid="account_no" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="account_no"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.account_no')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                id="account_no"
                                                                v-model="refund.account_no"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Refund By" vid="refund_by" rules="required|min_value:1">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="refund_by"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.refund_by')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-select
                                                                plain
                                                                v-model="refund.refund_by"
                                                                :options="refundByList"
                                                                id="refund_by"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                                <template v-slot:first>
                                                                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                                                                </template>
                                                            </b-form-select>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="6" sm="12">
                                                    <ValidationProvider name="Date" vid="operation_date" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="operation_date"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.date')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-input
                                                                class="date-picker"
                                                                v-model="refund.operation_date"
                                                                placeholder="Select Date"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col lg="12" sm="12">
                                                    <ValidationProvider name="Deduction Reason" vid="reason" rules="required">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-cols-lg="2"
                                                            label-for="reason"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                            <template v-slot:label>
                                                                {{$t('irrigation_config.refund_reason')}} <span class="text-danger">*</span>
                                                            </template>
                                                            <b-form-textarea
                                                                id="reason"
                                                                v-model="refund.reason"
                                                                :state="errors[0] ? false : (valid ? true : null)"
                                                            >
                                                            </b-form-textarea>
                                                            <div class="invalid-feedback">
                                                                {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                            </b-row>
                                            <div class="row">
                                                <div class="col-sm-3"></div>
                                                <div class="col text-right">
                                                    <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                                    &nbsp;
                                                    <b-button variant="danger" class="mr-1" @click="$bvModal.hide('refundModal')">{{ $t('globalTrans.cancel') }}</b-button>
                                                </div>
                                            </div>
                                        </b-form>
                                    </ValidationObserver>
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<style>
.refundForm label {
    font-size: 12px !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { refundDeductStore } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
    props: ['id', 'item'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    created () {
        // const applicationId = this.item.far_application_id
        // this.deduct = Object.assign({}, this.item, this.item.far_application_id)
    },
    mounted () {
        flatpickr('.date-picker', {})
        core.index()
    },
    data () {
        return {
            load: false,
            totalPost: 0,
            saveBtnName: this.id ? this.$t('globalTrans.save') : this.$t('globalTrans.save'),
            refund: {
                application_id: this.item.far_application_id,
                payment_id: this.item.id,
                application_type_id: this.item.application_type,
                payment_type_id: this.item.payment_type_id,
                operation_date: '',
                account_no: '',
                mobile_no: this.item.farmer_basic_infos.mobile_no,
                reason: '',
                refund_amount: '',
                operation_type: 1,
                user_name: this.$i18n.locale === 'bn' ? this.item.farmer_basic_infos.name_bn : this.item.farmer_basic_infos.name,
                refund_by: 0
            },
            payment_type_name: this.$i18n.locale === 'bn' ? this.item.payment_type_bn : this.item.payment_type,
            application_type_name: this.$i18n.locale === 'bn' ? this.item.application_type_bn : this.item.application_type_en
        }
    },
    computed: {
        refundByList: function () {
            return this.$store.state.commonObj.refundByList.map((obj, index) => {
                if (this.$i18n.locale === 'bn') {
                return { value: obj.id, text: obj.name_bn }
                } else {
                return { value: obj.id, text: obj.name }
                }
            })
        }
    },
    methods: {
        async register () {
            this.load = true
            this.$store.dispatch('mutateCommonProperties', { loading: true })
            let result = null
            result = await RestApi.postData(irriSchemeServiceBaseUrl, refundDeductStore, this.refund)

            if (result.success) {
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
                this.$store.dispatch('IrriConfig/mutateCommonObj', { hasDropdownLoaded: false })

                this.$toast.success({
                    title: 'Success',
                    message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
                    color: '#D6E09B'
                })
                this.$bvModal.hide('refundModal')
            } else {
                this.$toast.error({
                    title: 'Error',
                    message: result.errors
                })
                this.$refs.form.setErrors(result.errors)
            }
            this.load = false
        }
    }
}
</script>
